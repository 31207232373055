// Get the root element
var root = document.querySelector(":root");

const systemSettingDark = window.matchMedia("(prefers-color-scheme: dark)");
const localStorageTheme = localStorage.getItem("theme");

function calculateSettingAsThemeString({
  localStorageTheme,
  systemSettingDark,
})
{
  if (localStorageTheme !== null)
  {
    return localStorageTheme;
  }

  if (systemSettingDark.matches)
  {
    return "dark";
  }

  return "light";
}

let currentThemeSetting = calculateSettingAsThemeString({
  localStorageTheme,
  systemSettingDark,
});

if (currentThemeSetting === "dark")
{
  root.classList.add("dark-mode");
}

function changeThemeButtonIcon(theme)
{
  const icon = document.querySelector("[data-theme-toggle] #icon");
  if (theme === "dark")
  {
    icon.classList.add("fa-sun");
    icon.classList.remove("fa-moon");
  } else
  {
    icon.classList.add("fa-moon");
    icon.classList.remove("fa-sun");
  }
}

function toggleTheme(event)
{
  const newTheme = currentThemeSetting === "dark" ? "light" : "dark";
  if (newTheme === "dark")
  {
    root.classList.add("dark-mode");
  } else
  {
    root.classList.remove("dark-mode");
  }
  changeThemeButtonIcon(newTheme);
  localStorage.setItem("theme", newTheme);
  currentThemeSetting = newTheme;
}

document.addEventListener("DOMContentLoaded", (event) =>
{
  changeThemeButtonIcon(currentThemeSetting);
  const themeButton = document.querySelector("[data-theme-toggle]");
  themeButton.addEventListener("click", toggleTheme);
});
